import React, { useRef, useImperativeHandle } from "react";

import classes from "./Input.module.css";

const ResearchInput = React.forwardRef((props, ref) => {
    const { className = "" , boxName } = props;
    const classNames = Array.isArray(className) ? className : className.split(" ");

    const combinedClasses = classNames
        .map((cn) => classes[cn])
        .filter((cn) => cn)
        .join(" ");

    const inputRef = useRef();

    const activate = () => {
        inputRef.current.focus();
    };

    const changeHandler = props.onChange;
    const onChangeHandler = (e) => {
        if (changeHandler) changeHandler(e.target.value, boxName);
    };

    useImperativeHandle(ref, () => {
        return {
            focus: activate,
        };
    });
    return (
        <div className={`${classes.control} ${combinedClasses} ${props.isValid === false ? classes.invalid : ""}`}>
            <input
                ref={inputRef}
                {...props}
                name={props.name}
                type={props.type}
                id={props.id}
                value={props.value || ''}
                onChange={onChangeHandler}
                onBlur={props.onBlur}
                placeholder={props.placeholder}
                readOnly={props.readOnly}
                disabled={props.disabled}
            />
            <label htmlFor={props.id}>{props.label}</label>
        </div>
    );
});

export const MultiInput = React.forwardRef((props, ref) => {
    //인풋 박스가 여러개일때 상태관리를 한번에 하기 위함.
    //value 상태를 관리하는게 아닌 event를 관리함.
    const { className = "" , boxName } = props;
    const classNames = Array.isArray(className) ? className : className.split(" ");

    const combinedClasses = classNames
        .map((cn) => classes[cn])
        .filter((cn) => cn)
        .join(" ");

    const inputRef = useRef();

    const activate = () => {
        inputRef.current.focus();
    };

    const changeHandler = props.onChange;
    const onChangeHandler = (e) => {
        if (changeHandler) changeHandler(e, boxName);
    };

    useImperativeHandle(ref, () => {
        return {
            focus: activate,
        };
    });
    return (
        <div className={`${classes.control} ${combinedClasses} ${props.isValid === false ? classes.invalid : ""}`}>
            <input
                ref={inputRef}
                name={props.name}
                type={props.type}
                id={props.id}
                value={props.value}
                onChange={onChangeHandler}
                onBlur={props.onBlur}
                placeholder={props.placeholder}
                readOnly={props.readOnly}
                disabled={props.disabled}
            />
            <label htmlFor={props.id}>{props.label}</label>
        </div>
    );
});

export const BowlingBallInput = React.forwardRef((props, ref) => {
    // ↓ 퍼블단에서 화면 확인만을 위해 작성한 코드 입니다.
    //클래스명 조합
    const { className = "" , boxName } = props;
    const classNames = Array.isArray(className) ? className : className.split(" ");

    const combinedClasses = classNames
        .map((cn) => classes[cn])
        .filter((cn) => cn)
        .join(" ");

    const inputRef = useRef();

    const activate = () => {
        inputRef.current.focus();
    };

    const changeHandler = props.onChange;
    const onChangeHandler = (e) => {
        if (changeHandler) changeHandler(e);
    };

    useImperativeHandle(ref, () => {
        return {
            focus: activate,
        };
    });
    return (
        <div className={`${classes.control} ${combinedClasses} ${props.isValid === false ? classes.invalid : ""}`}>
            <input
                ref={inputRef}
                {...props}
                name={props.name}
                type={props.type}
                id={props.id}
                value={props.value}
                onChange={onChangeHandler}
                onBlur={props.onBlur}
                placeholder={props.placeholder}
                readOnly={props.readOnly}
                disabled={props.disabled}
            />
            <label htmlFor={props.id}>{props.label}</label>
        </div>
    );
});

export default ResearchInput;
