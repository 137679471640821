import _ from "lodash";
import instance from "../../store/AxiosInterceptor";

export async function ProductSelectedCategoryList({ page = 0, size = 10 }, searchParams = {}) {
    try {
        // console.log("page: " + page, "size:" + size);
        // console.log("searchParams:", searchParams);
        let url = `/api/v1/admin/product/selectList?page=${page}&size=${size}`;
        const trsParamMap = {
            searchKeyword: "name",
            selectedId: "category_id",
            depth: "category_depth",
            exposeYn: "expose_yn",
        };

        ["searchKeyword", "selectedId", "depth", "exposeYn"].forEach((key) => {
            if (searchParams[key]) url += `&${trsParamMap[key]}=${searchParams[key]}`;
        });
        // console.log('..............',{url})
        const response = await instance.get(url);

        return response;
    } catch (error) {
        return { error };
    }
}

export async function ProductSelectedCategoryListAll(
    { page = 0, size = 10000 },
    searchParams = {}
) {
    try {
        let url = `/api/v1/admin/product/selectListAll?page=${page}&size=${size}`;
        const trsParamMap = {
            searchKeyword: "name",
            selectedId: "category_id",
            depth: "category_depth",
            exposeYn: "expose_yn",
        };
        ["searchKeyword", "selectedId", "depth", "exposeYn"].forEach((key) => {
            if (searchParams[key]) url += `&${trsParamMap[key]}=${searchParams[key]}`;
        });
        // console.log('..............',{url})
        const response = await instance.get(url);
        return response;
    } catch (error) {
        return { error };
    }
}

export async function ProductSelectedCategoryDetail(id) {
    try {
        if (_.isNaN(Number(id))) throw new Error(`${id} is not number`);
        const url = `/api/v1/admin/product/select/${id}`;
        const response = await instance.get(url);
        return response;
    } catch (error) {
        console.error(error);
        return { error };
    }
}

export async function ProductSelectedCategoryInsert({
    isEdit,
    body,
    images = [],
    thumbnailImage = null,
}) {
    try {
        const url = `/api/v1/admin/product/${isEdit ? "update" : "add"}/PRODUCT`;
        // console.log({ url });
        const action = isEdit ? instance.put : instance.post;
        const formData = new FormData();
        formData.append("body", new Blob([JSON.stringify(body)], { type: "application/json" }));
        for (let i = 0; i < images.length; i++) {
            const image = images[i];
            if (image instanceof File) {
                formData.append("images", image);
            } else if (_.isString(image?.uri)) {
                const imgData = await (await fetch(image.uri)).blob();
                formData.append(
                    "images",
                    new File([imgData], image["origin_name"], {
                        type: `image/${image["origin_name"]?.split(".").at(-1)}`,
                    })
                );
            }
        }
        formData.append("thumbnailImage", thumbnailImage);
        const response = await action(url, formData);
        return response;
    } catch (error) {
        console.error(error);
        return { error };
    }
}

export async function ProductSelectedCategoryDelete(id) {
    try {
        const url = `/api/v1/admin/product/delete/${id}`;
        const response = await instance.delete(url);
        // console.log({ response });
        return response;
    } catch (error) {
        console.error(error);
        return { error };
    }
}
