import { useState, useEffect, useContext } from "react";
import { useNavigate, useLoaderData, useParams } from "react-router-dom";
import Button from "../../components/UI/Button/Button";
import Input, { BowlingBallInput } from "../../components/UI/Input/Input";
import classes from "./MyPageApp.module.css";
import { JwtTokenContext } from "../../store/JwtTokenProvider";
import instance from "../../store/AxiosInterceptor";
import SelectBoxNew from "../../components/UI/SelectBox/SelectBoxNew";
import Alert from "../../components/UI/Modal/Alert";
import ReactInicis from "../../components/inicis/reactInicis";
import { ReqCancelApplyOrPayment, ReqTransactionId } from "../../api/inicis/InicisApi";
import {
    ReqAddUserBowlingBall,
    ReqDeleteUserBowlingBall,
    ReqFindUserBowlingBall,
    ReqUpdateResearchResult,
    ReqUpdateUserBowlingBall,
} from "../../api/mypage/UserMyPageApi";
import { DefaultBallBrandList, DefaultDisableSelectBox2 } from "../../components/definedefault/DefSelectBoxValues";
import Modal from "../../components/UI/Modal/Modal";
import ResearchInput from "../../components/UI/Input/ResearchInput";

/**
 * @param{resData.competitor_apply_status} : 사용자 상태
 * @param{resData.apply_status} : 사용자 상태 한글값
 * @param{resData.main_process_type} : 대회 진행 상태
 * @param{resData.entry_fee} : 참가비(String)
 * @param{resData.main_apply_type} : 대회 선정 방식(D : 추첨식, O : 선착순)
 * @returns
 */

function MyTournamentDetailApp() {
    const loc = document.location.origin;
    const myDomain = loc ? loc : "https://www.hubilon.store";
    const resData = useLoaderData();
    const loginCtx = useContext(JwtTokenContext);
    const isLogin = loginCtx.isLogin();
    const { competitorId } = useParams();
    const navigate = useNavigate();
    const [researchList, setResearchList] = useState([]);
    const [researchResultList, setResearchResultList] = useState([]);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [modalText, setModalText] = useState("");
    const [showApplyCancelConfirm, setShowApplyCancelConfirm] = useState(false);
    const [showAttendCancelConfirm, setAttendCancelConfirm] = useState(false);
    const [showPaymentCancelConfirm, setPaymentCancelConfirm] = useState(false);
    const disabledList = DefaultDisableSelectBox2;
    const ballBrandList = DefaultBallBrandList;
    const [selectDisabled, setSelectDisabled] = useState(resData.disabled);
    const disableHandler = (value) => {
        setSelectDisabled(value);
    };

    //>>>참가자 신청 상태
    const applied = resData.competitor_apply_status === "applied";
    const waiting = resData.competitor_apply_status === "waiting";
    const chosen = resData.competitor_apply_status === "chosen";
    const chosen_1 = resData.competitor_apply_status === "chosen_1";
    const chosen_9 = resData.competitor_apply_status === "chosen_9";
    const not_chosen = resData.competitor_apply_status === "not_chosen";
    const cancel = resData.competitor_apply_status === "cancel";
    const cancel_not_pay = resData.competitor_apply_status === "cancel_not_pay";
    const cancel_apply = resData.competitor_apply_status === "cancel_apply";
    const cancel_waiting = resData.competitor_apply_status === "cancel_waiting";
    const complete = resData.competitor_apply_status === "complete";
    const not_attend_a = resData.competitor_apply_status === "not_attend_a";
    const not_attend_n = resData.competitor_apply_status === "not_attend_n";

    //대회 진행 상태
    const GENERATED = resData.main_process_type === "GENERATED";
    const APPLY = resData.main_process_type === "APPLY";
    const APPLY_END = resData.main_process_type === "APPLY_END";
    const DRAW = resData.main_process_type === "DRAW";
    const PLACEMENT = resData.main_process_type === "PLACEMENT";
    const START_FREE_PAYMENT = resData.main_process_type === "START_FREE_PAYMENT";
    const START_PAYMENT = resData.main_process_type === "START_PAYMENT";
    const FIRST_PAYMENT_END = resData.main_process_type === "FIRST_PAYMENT_END";
    const SECOND_PAYMENT_END = resData.main_process_type === "SECOND_PAYMENT_END";
    const THIRD_PAYMENT_END = resData.main_process_type === "THIRD_PAYMENT_END";
    const COMPLETE_APPLY = resData.main_process_type === "COMPLETE_APPLY";
    const CONTEST_END = resData.main_process_type === "CONTEST_END";

    //대회 접수 시간, 현재 시간이 접수 취소 시간보다 작으면 트루
    const cancelEndTime = new Date(resData.cancel_date);
    const currentTime = new Date();
    const isCancelDate = currentTime < cancelEndTime;

    const entryFee = resData.entry_fee !== "0"; //참가비가 0이 아니면 참가비가 존재한다.

    //해당 참가자가 결제했는지, 결제한 경우 트루
    const isPay = resData.pay_status === "Y";
    const exposeYn = resData.draw_expose_yn === "Y";
    const exposePosition = resData.expose_position === "Y";

    //>>>접수 방식
    const D = resData.main_apply_type === "D"; //추첨
    const O = resData.main_apply_type === "O"; //선착순
    //<<<접수 방식

    //결제 방식
    const paymentTypeIsCard = resData.paymentType === "CARD";

    const viewBirthDate = resData.member_birth
        ? `${resData.member_birth.slice(0, 4)}년 ${resData.member_birth.slice(4, 6)}월 ${resData.member_birth.slice(
              6,
              8
          )}일`
        : "";

    const viewPhoneNumber =
        resData.member_phone?.indexOf("-") === -1
            ? `${resData.member_phone.slice(0, 3)}-${resData.member_phone.slice(3, 7)}-${resData.member_phone.slice(
                  7,
                  11
              )}`
            : resData.member_phone;

    const researchListHandler = (result) => {
        if (result) {
            const curData = result.map((data, index) => {
                const array = [];
                const default_value = data.default_value !== "" ? data.default_value.split(",") : [];
                default_value.forEach((value, index) => {
                    array.push({ key: value, value: value });
                });
                return {
                    seq: data.seq,
                    title: data.title,
                    default_value: array.length === 0 ? "" : array[0].key,
                    values: array,
                };
            });
            setResearchList(curData);
        }
    };

    const inputBoxChangeHandler = (value, boxName) => {
        const copyList = researchResultList.map((item) =>
            item.title === boxName ? { ...item, value: value } : item
        );
        setResearchResultList(copyList);
    };
    
    const selectBoxNewCallback = (key, boxName) => {
        const copyList = researchResultList.map((item) =>
            item.title === boxName ? { ...item, value: key } : item
        );
        setResearchResultList(copyList);
    };

    const researchView = (
        <>
            {researchList.map((data, index) => {
                const researchItem = researchResultList.find((item) => item.title === data.title);
                const inputValue = researchItem ? researchItem.value : '';
                const showInputWarning = researchItem && researchItem.value === '';

                return (
                    <tr key={index}>
                        <th>{data.title}</th>
                        <td>
                            <div className={classes.flex_row}>
                                {data.values.length === 0 ? (
                                    <ResearchInput
                                        className="w_50"
                                        onChange={inputBoxChangeHandler}  // Passing handler directly
                                        boxName={data.title}
                                        value={inputValue}
                                    />
                                ) : (
                                    <SelectBoxNew
                                        className="default w_50"
                                        key={index}
                                        setSelectItemCB={selectBoxNewCallback}
                                        optionDatas={data.values}
                                        showDefaultKey={inputValue}
                                        boxName={data.title}
                                    />
                                )}
                                {showInputWarning && (
                                    <p className="require_red f_14">
                                        {`${data.title} 항목을 입력해주세요`}
                                    </p>
                                )}
                            </div>
                        </td>
                    </tr>
                );
            })}
        </>
    );

    const moveContestListHandler = () => {
        navigate("/myPage/tournamentHistory");
    };

    const moveContestDetail = () => {
        if (ballSuccessAlert) {
            setBallSuccessAlert(() => false);
        }
        if (deleteAlert) {
            setDeleteAlert(() => false);
        }
        if (editAlert) {
            setEditAlert(() => false);
        }
        closeModalHandler();
        navigate(`/myPage/tournamentHistory/${competitorId}`);
    };

    const modifyModalHandler = (state, modalText) => {
        setShowAlertModal(() => state);
        setModalText(() => modalText);
    };

    const alertModalCloseHandlerAndNavigate = (state, modalText) => {
        navigate("/myPage/tournamentHistory");
        setShowAlertModal(state);
        setModalText(modalText);
    };

    const applyCancelConfirmHandler = (state) => {
        setShowApplyCancelConfirm(state);
    };

    const attendCancelConfirmHandler = (state) => {
        setAttendCancelConfirm(state);
    };

    const paymentStateHandler = (state) => {
        setPaymentCancelConfirm(state);
    };

    //참가 취소 또는 결제취소 핸들러
    const cancelApplyOrPaymentHandler = () => {
        setPaymentCancelConfirm(false);
        ReqCancelApplyOrPayment(
            resData.contest_id,
            resData.competitor_id,
            resData.pay_status,
            resData.paymentType,
            (result) => {
                if (result.status === 200) {
                    modifyModalHandler(true, "참가 취소 되었습니다.");
                } else {
                    modifyModalHandler(true, "참가 취소가 실패.");
                }
            },
            (error_result) => {
                console.log(error_result);
            }
        );
    };

    const [valueModal, setValueModal] = useState(false);

    const onChangeInfoHandler = () => {
        const filterArray = researchResultList.filter((data) => {
            return data.value.trim() === "";
        });
        if (filterArray.length > 0) {
            setValueModal(true);
            return;
        }
        const newResultList = researchResultList.length > 0 ? researchResultList.map((item,index) => {
            return {
                seq : index + 1,
                title : item.title,
                value : item.value,
            }
        }) : [];
        ReqUpdateResearchResult(
            competitorId,
            newResultList,
            selectDisabled,
            (result) => {
                if (result.status === 200) {
                    modifyModalHandler(true, "접수내역이 수정되었습니다.");
                }
            },
            (error_result) => {
                modifyModalHandler(true, "접수수정 실패.");
            }
        );
    };

    useEffect(() => {
        if (!isLogin) {
            navigate("/login");
            return;
        }
    }, [isLogin]);

    useEffect(() => {
        researchListHandler(resData.research_list);
        setResearchResultList(resData.research_result_list);
    }, [resData]);

    useEffect(() => {
        // researchResultList가 비어있을 때만 초기화
        if (researchResultList.length === 0 && researchList.length > 0) {
            const initializedList = researchList.map((item) => ({
                title: item.title,
                value: item.values.length > 0 ? '' : undefined, // SelectBox인지 Input인지에 따라 초기값 설정
            }));
            setResearchResultList(initializedList);
        }
    }, [researchList]); // 의존성 배열에서 researchResultList를 제거

    const isMobile = true;
    const [transactionId, setTransactionId] = useState(); //oid로 사용한다.
    const [isPurchase, setIsPurchase] = useState(false);
    const [mid, setMid] = useState("");
    const [mKey, setMKey] = useState("");

    const transactionData = {
        price: resData.entry_fee,
        contestId: resData.contest_id,
    };

    /**
     * 모바일이랑 PC랑 결제 API가 다르다.
     * 모바일 카드 결제, 실시간 계좌이체 파라미터값 분리해서 넣어줘야함.
     */
    const [mobilePayMethod, setMobilePayMethod] = useState("");
    const cardData = {
        productName: resData.contest_title,
        buyerName: resData.member_name,
        buyerTel: resData.member_phone,
        buyerEmail: "",
        productPrice: resData.entry_fee,
        returnUrl: `${myDomain}/api/v1/home/inicis/mobile/auth?transactionId=${transactionId}`,
        closeUrl: `${myDomain}/api/v1/home/inicis/mobile/auth?transactionId=${transactionId}`,
    };

    const transactionHandler = (payMethod) => {
        setMobilePayMethod(payMethod);
        ReqTransactionId(
            transactionData,
            (result) => {
                if (result.status === 200) {
                    setTransactionId(result.transactionId);
                    setMid(result.mid);
                    setMKey(result.mkey);
                    setIsPurchase(true);
                }
            },
            (error_result) => {
                console.log(error_result);
                setTransactionId("tId생성 실패");
                setMid("tId생성 실패");
                setMKey("tId생성 실패");
                setIsPurchase(false);
            }
        );
    };
    // 사용구 등록 수정 팝업 여닫기
    const [items, setItems] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModalHandler = () => {
        findUserBallHandler();
        setIsModalOpen(true);
    };

    const closeModalHandler = () => {
        setItems([]);
        setIsModalOpen(false);
    };

    const handleAddItem = () => {
        if (items.length < 10) {
            setItems([
                ...items,
                {
                    brand: "",
                    model: "",
                    serial1: "",
                    serial2: "",
                    serial3: "",
                    confirm: false,
                    isEditing: true,
                    isNew: true,
                },
            ]);
        }
    };

    const handleBrandChange = (index, value) => {
        const updatedItems = [...items];
        updatedItems[index].brand = value;
        setItems(updatedItems);
    };

    const handleModelChange = (index, value) => {
        const updatedItems = [...items];
        updatedItems[index][`model`] = value;
        setItems(updatedItems);
    };

    const handleInputChange = (index, inputIndex, value) => {
        if (value.length <= 4) {
            const updatedItems = [...items];
            updatedItems[index][`serial${inputIndex}`] = value;
            setItems(updatedItems);
        }
    };

    const toggleEdit = (index, state) => {
        if (state) {
            setItems((prevItems) => {
                const updatedItems = [...prevItems];
                updatedItems[index].isEditing = state;

                if (updatedItems[index].isEditing) {
                    const serial = updatedItems[index].serial;
                    const [serial1, serial2, serial3] = serial.match(/\w{1,4}/g);
                    updatedItems[index]["serial1"] = serial1 ? serial1 : "";
                    updatedItems[index]["serial2"] = serial2 ? serial2 : "";
                    updatedItems[index]["serial3"] = serial3 ? serial3 : "";
                }

                return updatedItems;
            });
        } else {
            setItems((prevItems) => {
                const updatedItems = [...prevItems];
                updatedItems[index].isEditing = state;

                return updatedItems;
            });
        }
    };

    const [ballErrorAlert, setBallErrorAlert] = useState(false);
    const [ballSuccessAlert, setBallSuccessAlert] = useState(false);
    const [ballDeleteConfirm, setBallDeleteConfirm] = useState(false);
    //수정, 삭제 시 해당 변수를 사용한다.
    const [editBallId, setEditBallId] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [editAlert, setEditAlert] = useState(false);

    const deletedBallHandler = (state, ballId) => {
        setBallDeleteConfirm(state);
        setEditBallId(ballId);
    };

    const editHandler = (ballId, index) => {
        setEditBallId(ballId);
        const updateObject = {
            brand: items[index].brand,
            model: items[index].model,
            serialNo: `${items[index]["serial1"]}${items[index]["serial2"] ? items[index]["serial2"] : ""}${
                items[index]["serial3"] ? items[index]["serial3"] : ""
            }`,
        };

        ReqUpdateUserBowlingBall(
            ballId,
            updateObject,
            (result) => {
                if (result.status === 200) {
                    setEditAlert(true);
                }
            },
            (error_result) => {
                alert("error");
            }
        );
    };

    const ballDeleteHandler = (ballId) => {
        ReqDeleteUserBowlingBall(
            competitorId,
            ballId,
            (result) => {
                if (result.status === 200) {
                    setDeleteAlert(true);
                }
            },
            (error_result) => {
                alert("error");
            }
        );
    };

    const bowlingBallSaveHandler = () => {
        if (items?.length === 0) {
            setBallErrorAlert(true);
            return;
        }

        const newItems = items
            .filter((data) => {
                return data.isNew;
            })
            .filter((data) => {
                return !(data.brand?.length < 1 || data.model?.length < 1 || data.serialNo?.length < 1);
            })
            .map((data) => {
                return {
                    brand: data.brand,
                    model: data.model,
                    serialNo: `${data.serial1}${data.serial2}${data.serial3}`,
                    competitorId: competitorId,
                };
            });

        if (newItems?.length === 0) {
            setBallErrorAlert(true);
            return;
        }

        const serialNoErrorList = newItems.filter((data) => data.serialNo.length > 12);

        if (serialNoErrorList.length > 0) {
            console.log(serialNoErrorList);
            alert("시리얼 번호의 최대 길이는 12자리입니다.");
            return;
        }

        ReqAddUserBowlingBall(
            newItems,
            (result) => {
                if (result.status === 200) {
                    setBallSuccessAlert(true);
                    return;
                }
            },
            (error_result) => {
                alert("error");
                return;
            }
        );
    };

    const findUserBallHandler = () => {
        ReqFindUserBowlingBall(
            competitorId,
            (result) => {
                const resData = result.data.map((data) => {
                    return {
                        ballId: data.ball_id,
                        brand: data.brand_name,
                        serial: data.ball_sn,
                        model: data.ball_name,
                        confirm: data.confirm === "Y",
                        isEditing: false,
                        isNew: false,
                    };
                });
                setItems(resData);
            },
            (error_result) => {
                setItems([]);
            }
        );
    };
    return (
        <div className={classes.contents_wrap}>
            <div className={classes.contents_title}>
                <h3>대회 접수내역</h3>
                <p className={classes.desc}>*이름/휴대폰/성별/생년월일 수정은 회원정보수정에서 가능합니다.</p>
            </div>
            <div className={classes.detail_table_wrap}>
                <table>
                    {/* 모바일에서 사이즈 다름 */}
                    <colgroup>
                        <col style={{ width: "20%" }} />
                        <col style={{ width: "80%" }} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>대회</th>
                            <td>{resData.contest_title}</td>
                        </tr>
                        <tr>
                            <th>경기장</th>
                            <td>
                                {resData.region} ({resData.center_name})
                            </td>
                        </tr>
                        <tr>
                            <th>조</th>
                            <td>{resData.group_name ? resData.group_name : resData.group_seq}조</td>
                        </tr>
                        <tr>
                            <th>이름</th>
                            <td>{resData.member_name}</td>
                        </tr>
                        <tr>
                            <th>휴대폰</th>
                            <td>{viewPhoneNumber}</td>
                        </tr>
                        <tr>
                            <th>성별</th>
                            <td>{resData.member_gender === "M" ? "남자" : "여자"}</td>
                        </tr>
                        <tr>
                            <th>생년월일</th>
                            <td>{viewBirthDate}</td>
                        </tr>
                        <tr>
                            <th>장애여부</th>
                            <td>
                                <SelectBoxNew
                                    className="default w_100"
                                    setSelectItemCB={disableHandler}
                                    optionDatas={disabledList}
                                    showDefaultKey={selectDisabled}
                                />
                            </td>
                        </tr>
                        {researchView}
                        <tr>
                            <th>레인</th>
                            <td>
                                {exposePosition
                                    ? `${resData.lane == null ? "0" : resData.lane}-${resData.seq_of_lane}`
                                    : "-"}
                            </td>
                        </tr>
                        <tr>
                            <th>핸디</th>
                            <td>{resData.handicap}점</td>
                        </tr>
                        <tr>
                            <th>참가비</th>
                            <td>{resData.entry_fee} 원</td>
                        </tr>
                        <tr>
                            <th>상태</th>
                            {applied && <td>접수</td>}
                            {cancel_apply && <td>접수취소</td>}
                            {cancel && <td>참가취소</td>}
                            {cancel_waiting && <td>대기취소</td>}
                            {cancel_not_pay && <td>참가취소(미결제)</td>}
                            {!exposeYn &&
                                !applied &&
                                !cancel_apply &&
                                !cancel &&
                                !cancel_waiting &&
                                !cancel_not_pay && <td>-</td>}

                            {exposeYn && waiting && <td>대기 {resData.seq}번</td>}

                            {exposeYn && not_chosen && <td>비선정</td>}

                            {exposeYn && (chosen || chosen_1 || chosen_9) && isPay && entryFee && (
                                <td>선정(결제완료)</td>
                            )}

                            {exposeYn && (chosen || chosen_1 || chosen_9) && !isPay && entryFee && (
                                <td>선정(미결제)</td>
                            )}

                            {exposeYn && (chosen || chosen_1 || chosen_9) && !isPay && !entryFee && <td>선정</td>}

                            {exposeYn && complete && <td>참가등록</td>}

                            {(not_attend_a || not_attend_n) && <td>불참</td>}
                        </tr>
                        {!paymentTypeIsCard && (
                            <>
                                <tr>
                                    <th>은행명</th>
                                    <td>{resData.bankName}</td>
                                </tr>
                                <tr>
                                    <th>예금주</th>
                                    <td>{resData.bankOwner}</td>
                                </tr>
                                <tr>
                                    <th>계좌번호</th>
                                    <td>{resData.bankAccount}</td>
                                </tr>
                            </>
                        )}
                        {/* 사용구등록/수정-추가 */}
                        {resData.use_ball_yn === "Y" && (
                            <tr>
                                <th>사용구</th>
                                <td>
                                    {resData.register_ball_is_possible === "Y" ? (
                                        <Button className="border sm" onClick={openModalHandler}>
                                            사용구 등록/수정
                                        </Button>
                                    ) : (
                                        <p>사용구 등록 기한이 만료되었습니다.</p>
                                    )}
                                </td>
                            </tr>
                        )}
                        {/* 사용구등록/수정-추가 */}
                        {/* 사용구 등록 수정 팝업 */}
                        {isModalOpen && (
                            <Modal close={closeModalHandler} header="사용구 등록/수정">
                                <main>
                                    <div className={classes.info_list_wrap}>
                                        <h4>[안내]</h4>
                                        <ul>
                                            <li>대회사용구 등록은 STORM, Roto Grip, 900 Global만 가능합니다.</li>
                                            <li>
                                                볼링공 시리얼넘버는 최대 12자리이고 12자리 보다 적은 경우도 있습니다.
                                            </li>
                                            <li>
                                                사용하시는 볼링공의 시리얼넘버가 ‘미확인‘ 인 경우에는 ‘1:1문의‘로
                                                접수하시기 바랍니다.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className={classes.register_ball_table_wrap}>
                                        <div className={classes.pop_mobile_table_wrap}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>브랜드</th>
                                                        <th>모델명</th>
                                                        <th>시리얼넘버</th>
                                                        <th>등록확인</th>
                                                        <th>수정/삭제</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {items.map((item, index) => (
                                                        <tr key={item.ballId} className={classes.register_ball}>
                                                            <td>
                                                                {item.isEditing ? (
                                                                    <SelectBoxNew
                                                                        className="default w_100"
                                                                        setSelectItemCB={(value) =>
                                                                            handleBrandChange(index, value)
                                                                        }
                                                                        optionDatas={ballBrandList}
                                                                        showDefaultKey={item.brand}
                                                                    />
                                                                ) : (
                                                                    item.brand
                                                                )}
                                                            </td>
                                                            <td>
                                                                {item.isEditing ? (
                                                                    <BowlingBallInput
                                                                        value={item.model}
                                                                        onChange={(e) =>
                                                                            handleModelChange(index, e.target.value)
                                                                        }
                                                                    />
                                                                ) : (
                                                                    item.model
                                                                )}
                                                            </td>
                                                            <td>
                                                                {item.isEditing ? (
                                                                    <div className={classes.serial_num}>
                                                                        <BowlingBallInput
                                                                            value={item.serial1}
                                                                            onChange={(e) =>
                                                                                handleInputChange(
                                                                                    index,
                                                                                    1,
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        />
                                                                        <BowlingBallInput
                                                                            value={item.serial2}
                                                                            onChange={(e) =>
                                                                                handleInputChange(
                                                                                    index,
                                                                                    2,
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        />
                                                                        <BowlingBallInput
                                                                            value={item.serial3}
                                                                            onChange={(e) =>
                                                                                handleInputChange(
                                                                                    index,
                                                                                    3,
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    item.serial
                                                                )}
                                                            </td>
                                                            {!item.isNew && item.confirm && <td>확인</td>}
                                                            {!item.isNew && !item.confirm && <td>미확인</td>}
                                                            {item.isNew && !item.confirm && <td></td>}
                                                            <td>
                                                                {!item.isNew && item.isEditing && (
                                                                    <div className={classes.flex_row}>
                                                                        <Button
                                                                            className="secondary xs"
                                                                            onClick={() =>
                                                                                editHandler(item.ballId, index)
                                                                            }
                                                                        >
                                                                            확인
                                                                        </Button>
                                                                        <Button
                                                                            className="border xs"
                                                                            onClick={() => toggleEdit(index, false)}
                                                                        >
                                                                            취소
                                                                        </Button>
                                                                    </div>
                                                                )}
                                                                {!item.isNew && !item.isEditing && (
                                                                    <div className={classes.flex_row}>
                                                                        <Button
                                                                            className="border xs"
                                                                            onClick={() => toggleEdit(index, true)}
                                                                        >
                                                                            수정
                                                                        </Button>
                                                                        <Button
                                                                            className="border xs"
                                                                            onClick={() =>
                                                                                deletedBallHandler(true, item.ballId)
                                                                            }
                                                                        >
                                                                            삭제
                                                                        </Button>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    {items.length < 10 && (
                                                        <tr>
                                                            <td colSpan={6}>
                                                                <Button
                                                                    className="border plus sm"
                                                                    onClick={handleAddItem}
                                                                >
                                                                    추가하기
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                            <div className={classes.btn_bottom_wrap}>
                                                <Button className="secondary sm" onClick={bowlingBallSaveHandler}>
                                                    등록하기
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </main>
                            </Modal>
                        )}
                        {/* 사용구 등록 수정 팝업 */}
                    </tbody>
                </table>
                <div className={classes.btn_bottom_wrap}>
                    {!not_chosen &&
                        !cancel &&
                        !cancel_not_pay &&
                        !cancel_apply &&
                        !cancel_waiting &&
                        !GENERATED &&
                        !COMPLETE_APPLY &&
                        !CONTEST_END && (
                            <Button className="secondary" onClick={onChangeInfoHandler}>
                                접수정보 수정
                            </Button>
                        )}

                    {/**참가비가 없는 경우 시작 */}
                    {!entryFee && applied && D && isCancelDate && (
                        <>
                            {/** 참가비가없고, applied, 추첨, 참가취소기간전  */}
                            <Button className="border" onClick={() => applyCancelConfirmHandler(true)}>
                                접수 취소
                            </Button>
                        </>
                    )}
                    {!entryFee && (waiting || chosen || chosen_1) && isCancelDate && (
                        <>
                            {/** 참가비가없고, 선정 또는 선정1 또는 대기, 참가취소 마감일 전*/}
                            {exposeYn && (
                                <Button className="border" onClick={() => applyCancelConfirmHandler(true)}>
                                    참가 취소
                                </Button>
                            )}
                        </>
                    )}
                    {/**참가비가 없는 경우 끝 */}

                    {/**참가비가 존재하는 경우 시작 */}
                    {entryFee && applied && D && (APPLY || APPLY_END) && (
                        <>
                            {/**참가비있고, 추첨이고 접수중, 접수완료일때 취소 */}
                            <Button className="border" onClick={() => applyCancelConfirmHandler(true)}>
                                접수 취소
                            </Button>
                        </>
                    )}
                    {entryFee && (chosen || chosen_1 || waiting) && O && (APPLY || APPLY_END) && (
                        <>
                            {/**참가비, 선착순, (선정,선정1,대기) 접수중, 접수완료 취소*/}
                            <Button className="border" onClick={() => applyCancelConfirmHandler(true)}>
                                접수 취소
                            </Button>
                        </>
                    )}
                    {entryFee && waiting && (START_PAYMENT || FIRST_PAYMENT_END || SECOND_PAYMENT_END) && (
                        <>
                            {/** 참가비가있고, (대기), (결제 시작 ~ 2차마감까지)일때 참가 취소가능*/}
                            {exposeYn && (
                                <Button className="border" onClick={() => applyCancelConfirmHandler(true)}>
                                    참가 취소
                                </Button>
                            )}
                        </>
                    )}
                    {entryFee && !isPay && (chosen || chosen_1) && (START_PAYMENT || FIRST_PAYMENT_END) && (
                        <>
                            {/** 참가비가있고, 결제를 안한 선정 또는 선정1, 결제시작 ~ 1차 결제 마감전까지 */}
                            {exposeYn && (
                                <Button className="border" onClick={() => applyCancelConfirmHandler(true)}>
                                    참가 취소
                                </Button>
                            )}
                            {paymentTypeIsCard && exposeYn && (
                                <Button className="secondary" onClick={() => transactionHandler("CARD")}>
                                    카드결제
                                </Button>
                            )}
                            {paymentTypeIsCard && exposeYn && (
                                <Button className="secondary" onClick={() => transactionHandler("BANK")}>
                                    계좌이체
                                </Button>
                            )}
                        </>
                    )}
                    {entryFee && isPay && (chosen || chosen_1) && (START_PAYMENT || FIRST_PAYMENT_END) && (
                        <>
                            {/** 참가비가있고, 결제를 한 선정 또는 선정1, 결제시작 ~ 1차 결제 마감전까지*/}
                            <Button className="border" onClick={() => paymentStateHandler(true)}>
                                결제 취소
                            </Button>
                        </>
                    )}
                    {entryFee && chosen_9 && (START_PAYMENT || FIRST_PAYMENT_END || SECOND_PAYMENT_END) && !isPay && (
                        <>
                            {/** 참가비가있고, 결제안한 선정9, 결제시작 ~ 2차 결제 마감 전까지*/}
                            {exposeYn && (
                                <Button className="border" onClick={() => applyCancelConfirmHandler(true)}>
                                    참가 취소
                                </Button>
                            )}
                            {paymentTypeIsCard && exposeYn && (
                                <Button className="secondary" onClick={() => transactionHandler("CARD")}>
                                    카드결제
                                </Button>
                            )}
                            {paymentTypeIsCard && exposeYn && (
                                <Button className="secondary" onClick={() => transactionHandler("BANK")}>
                                    계좌이체
                                </Button>
                            )}
                        </>
                    )}
                    {entryFee && chosen_9 && (START_PAYMENT || FIRST_PAYMENT_END || SECOND_PAYMENT_END) && isPay && (
                        <>
                            {/** 참가비가있고, 결제를 한 선정9,결제시작 ~ 2차 결제 마감 전까지 */}
                            <Button className="border" onClick={() => paymentStateHandler(true)}>
                                결제 취소
                            </Button>
                        </>
                    )}
                    <Button onClick={moveContestListHandler}>목록</Button>
                </div>
                {showAlertModal && (
                    <Alert type="alert" confirm="확인" close={() => alertModalCloseHandlerAndNavigate(false, "")}>
                        {modalText}
                    </Alert>
                )}
                {showApplyCancelConfirm && (
                    <Alert
                        type="confirm"
                        cancel="취소"
                        confirm="확인"
                        close={() => applyCancelConfirmHandler(false)}
                        confirmHandler={cancelApplyOrPaymentHandler}
                    >
                        {"접수를 취소하시겠습니까?"}
                    </Alert>
                )}
                {showAttendCancelConfirm && (
                    <Alert
                        type="confirm"
                        cancel="취소"
                        confirm="확인"
                        close={() => attendCancelConfirmHandler(false)}
                        confirmHandler={cancelApplyOrPaymentHandler}
                    >
                        {"참가를 취소하시겠습니까?"}
                    </Alert>
                )}
                {showPaymentCancelConfirm && (
                    <Alert
                        type="confirm"
                        cancel="취소"
                        confirm="확인"
                        close={() => paymentStateHandler(false)}
                        confirmHandler={cancelApplyOrPaymentHandler}
                    >
                        {"결제를 취소하시겠습니까? 결제 취소시 참가취소됩니다."}
                    </Alert>
                )}
                {valueModal && (
                    <Alert type="alert" confirm="확인" close={() => setValueModal(false)}>
                        {"입력 항목을 확인해주세요"}
                    </Alert>
                )}
                {ballErrorAlert && (
                    <Alert type="alert" confirm="확인" close={() => setBallErrorAlert(false)}>
                        {"사용구 정보를 입력해주세요."}
                    </Alert>
                )}
                {ballSuccessAlert && (
                    <Alert type="alert" confirm="확인" close={moveContestDetail}>
                        {"사용구가 등록되었습니다."}
                    </Alert>
                )}
                {deleteAlert && (
                    <Alert type="alert" confirm="확인" close={moveContestDetail}>
                        {"삭제되었습니다."}
                    </Alert>
                )}
                {editAlert && (
                    <Alert type="alert" confirm="확인" close={moveContestDetail}>
                        {"수정되었습니다."}
                    </Alert>
                )}
                {ballDeleteConfirm && (
                    <Alert
                        type="confirm"
                        cancel="취소"
                        confirm="확인"
                        close={() => deletedBallHandler(false, null)}
                        confirmHandler={() => ballDeleteHandler(editBallId)}
                    >
                        {"삭제하시겠습니까?"}
                    </Alert>
                )}
                <ReactInicis
                    payData={cardData}
                    isPurchase={isPurchase}
                    oid={transactionId}
                    mid={mid}
                    mKey={mKey}
                    isMobile={isMobile}
                    mobilePayMethod={mobilePayMethod}
                />
            </div>
        </div>
    );
}

export default MyTournamentDetailApp;

//>>>신청한 대회 상세정보 뿌려주기 Loader
export const loader = async ({ params }) => {
    const response = await instance({
        method: "GET",
        url: `/api/v1/home/mypage/contest/${params.competitorId}`,
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    }).catch(function (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return null;
        } else {
            return null;
        }
    });

    if (response == null || response.status !== 200) {
        return [];
    }
    if (response.data.status === 200) {
        return response.data;
    } else {
        return [];
    }
};
//<<<신청한 대회 상세정보 뿌려주기 Loader
