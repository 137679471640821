import { useContext, useEffect, useMemo, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { JwtTokenContext } from "../store/JwtTokenProvider";
import classes from "./MainNavigation.module.css";
import Button from "./UI/Button/Button";
import { ReqSignOut, reqTimeAdd } from "../api/auth/AuthApi";
import { useMemStore } from "../store/memstore/MemStore";
import _, { toInteger } from "lodash";
import Alert from "./UI/Modal/Alert";

function MainNavigation(props) {
    const setMemStore = useMemStore(false)[1];
    const location = useLocation();
    const loginCtx = useContext(JwtTokenContext);
    const navigate = useNavigate();
    const userName = loginCtx.decodedToken ? loginCtx.decodedToken.mname ? loginCtx.decodedToken.mname?.replaceAll("+", " ") : loginCtx.decodedToken.jti : "";

    const isAdminUri = location.pathname.startsWith("/admin");
    const isTournamentUri = location.pathname.startsWith("/tournament");
    // ↓ ↓ admin 화면 확인을 위하여 상태를 수정하였습니다.
    const isAdmin = isAdminUri ? loginCtx.isAdmin() : false;
    const isUserLogin = loginCtx.isLogin() ? (loginCtx.isAdmin() ? false : true) : false;
    const isExpiredToken = loginCtx.isAccessExpired();

    // // 메인 화면의 대회 dropdown menu
    const [navToggle, setNavToggle] = useState(false);
    const navOpenHandler = () => {
        setNavToggle(true);
    };
    const navCloseHandler = () => {
        setNavToggle(false);
    };

    useEffect(() => {
        const pathname = location.pathname;
        navCloseHandler();

        return () => {};
    }, [location.pathname]);

    // 로그아웃된 상황에서 어드민을 접속하면 튕겨내기 위함
    useEffect(() => {
        if (isAdminUri && isUserLogin) {
            return;
        }
        if (localStorage.getItem("token") == null) {
            if (isAdminUri) navigate("/");
            return;
        }
        if (isAdminUri && !isAdmin) {
            if (Object.keys(loginCtx.decodedToken).length === 0 && localStorage.getItem("token") != null) {
                // Do Nothing
                // F5로 새로고침하는 상황에서 TokenLoading 완료되지 않아도 해당 useEffect가 탈때가 발생함
                // 해당 타이밍에는 Token이 로딩되지 않았기 이를 감지하여 TokenProvider에서 토큰을 로딩할 타이밍을 벌어준다.
            }
            else {
                alert("사용시간 만료로 로그아웃 되었습니다. 다시 로그인 해주세요.");
                navigate("/adminlogin");    
            }
        }
        return () => {};
    }, [loginCtx, isExpiredToken]);


    const [expTime, setExpTime] = useState(1800);

    useEffect(() => {
        if(!isAdmin) return;
        let cnt = 0;
        const intervalTime = setInterval(() => {
            if(expTime <= 0) {
                clearInterval(intervalTime);
            }
            const newExpTime = _.toInteger(loginCtx.decodedToken.exp - new Date() / 1000);
            // if(cnt <5) {cnt ++}else{setShowWarningTimeAlert(true); cnt = 0;}
            setExpTime(newExpTime);
        }, 1000); // 1초마다 갱신
    
        return () => clearInterval(intervalTime);
    }, [loginCtx?.decodedToken?.exp]);

    const nowExpTime = useMemo(() => {
        if (isUserLogin) {
            return;
        }
        if(expTime <= 0){
            if (isAdminUri) {
                alert("사용시간 만료로 로그아웃 되었습니다. 다시 로그인 해주세요.");
                navigate("/adminlogin");
            }
            return null;
        }
        return `남은시간: ${(_.toInteger(expTime / 60)).toString().padStart(2,'0')}분 ${(expTime % 60).toString().padStart(2,'0')}초`;
    }, [expTime]);

    const signOutHandler = async () => {
        await ReqSignOut(
            loginCtx,
            () => {
                loginCtx.removeToken();
                if (isAdmin) navigate("/adminlogin");
                else if (isTournamentUri) navigate("/tournament");
                else navigate("/");
            }
        );
    };

    const openNewWindow = () => {
        // 새 창으로 URL 열기
        window.open("https://bowlingkoreamall.com/", "_blank");
    };

    const setMovePage = () => {
        if (isTournamentUri)
            setMemStore("LOGIN_MOVE_PAGE", { uri: "/tournament" });
    };

    /**
     * 상단 내비게이션 우측에 있는 login mypage 아이콘 표시
     */
    const homeLoginMyPageBlock = (
        <div className={classes.login_wrap}>
            <ul>
                {!isUserLogin && (
                    <li className={classes.login}>
                        <NavLink to="/login" onClick={setMovePage()}>
                            LOGIN
                        </NavLink>
                    </li>
                )}
                {isUserLogin && (
                    <li className={classes.logout}>
                        <a
                            className={classes.toggle_home}
                            onClick={signOutHandler}
                        >
                            LOGOUT
                        </a>
                    </li>
                )}
                <li className={classes.mypage}>
                    <NavLink to="/myPage">MYPAGE</NavLink>
                </li>
            </ul>
        </div>
    );

    const userMenu = () => {
        return (
            <ul>
                <li>
                    <NavLink to="/balls">볼링볼</NavLink>
                </li>
                <li>
                    <NavLink to="/bags">볼링가방</NavLink>
                </li>
                <li>
                    <NavLink to="/shoes">볼링화</NavLink>
                </li>
                <li>
                    <NavLink to="/clothes">볼링의류</NavLink>
                </li>
                <li>
                    <NavLink to="/products">볼링용품</NavLink>
                </li>
                <li>
                    <NavLink to="/guard">볼링아대</NavLink>
                </li>
                <li>
                    <NavLink to="/kegel">케겔</NavLink>
                </li>
                <li>
                    <NavLink to="/media">커뮤니티</NavLink>
                </li>
                <li>
                    <NavLink to="/intro">회사소개</NavLink>
                </li>
                <li>
                    <NavLink to="/event">이벤트</NavLink>
                </li>
            </ul>
        );
    };
    const tournamentMenu = () => {
        return (
            <ul>
                <li>
                    <NavLink to="/tournament/notice">대회공지</NavLink>
                </li>
                <li>
                    <NavLink to="/tournament/apply">대회접수</NavLink>
                </li>
                <li>
                    <NavLink to="/tournament/list">대회목록</NavLink>
                </li>
                <li>
                    <NavLink to="/tournament/result">대회결과</NavLink>
                </li>
            </ul>
        );
    };

    const adminMenu = () => {

        // UserType 이 볼링센터이면
        if (loginCtx?.userType === 'C') {
            return (
                <ul>
                    <li>
                        <NavLink
                            to="/admin/tournament"
                            exact
                            activeClassName="active"
                        >
                            대회운영
                        </NavLink>
                    </li>
                </ul>
            );
        }
        else {
            return (
                <ul>
                    <li>
                        <NavLink
                            to="/admin/tournament"
                            exact
                            activeClassName="active"
                        >
                            대회운영
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/admin/product" exact activeClassName="active">
                            상품관리
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/admin/kegel">케겔</NavLink>
                    </li>
                    <li>
                        <NavLink to="/admin/community">커뮤니티</NavLink>
                    </li>
                    <li>
                        <NavLink to="/admin/company">회사소개</NavLink>
                    </li>
                    <li>
                        <NavLink to="/admin/event">이벤트</NavLink>
                    </li>
                    <li>
                        <NavLink to="/admin/users">회원관리</NavLink>
                    </li>
                    <li>
                        <NavLink to="/admin/site">웹사이트관리</NavLink>
                    </li>
                    <li>
                        <NavLink to="/admin/statistics">통계</NavLink>
                    </li>
                </ul>
            );
        }
    };
    const [showWarningTimeAlert, setShowWarningTimeAlert] = useState(false);
    useEffect(() => {
        if(_.toInteger(expTime) === 300) {
            setShowWarningTimeAlert(true);
        }
    }, [expTime]);

    const refreshTokenHandler = async (loginCtx) => {
        const response = await reqTimeAdd(loginCtx);
        if(response?.data?.status === 200){
            loginCtx.addToken({
                token: response.data.access_token,
                refreshToken: response.data.refresh_token,
            });
        }
        else {
            alert(`토큰갱신 실패 응답 에러[${response.status}]`)
        }

        setShowWarningTimeAlert(false);
    }

    const adminMenuBlock = (
        <>
            {showWarningTimeAlert && (
            <Alert
                type='confirm'
                close={() => {setShowWarningTimeAlert(false)}}
                confirmHandler={() => { refreshTokenHandler(loginCtx) }}
                cancel="취소"
                confirm="연장"
            >
                로그인 시간이 5분 이하로 남았습니다.<br />
                연장하시겠습니까?
            </Alert>)}
            <header className={` ${classes.header} ${classes.admin_header} `}>
                <div className={classes.header_wrap}>
                    <h1 className={classes.admin_header_logo}>관리자 페이지</h1>
                    <nav className={classes.admin_nav}>{adminMenu()}</nav>
                    <div style={{ color: 'white' }}>
                        {nowExpTime}
                    </div>
                    <div className={classes.admin_user_info}>
                        <div className={classes.user}>
                            <p>
                                <b>
                                    {userName}
                                </b>
                                님 환영합니다
                            </p>
                        </div>
                        <Button className="xxs white" onClick={signOutHandler}>
                            로그아웃
                        </Button>
                    </div>
                </div>
            </header>
        </>
    );

    const mainMenuBlock = (
        <>
            <h1 className={classes.header_logo}>
                <NavLink to="/">(주)진승</NavLink>
            </h1>
            <div
                onMouseEnter={navOpenHandler}
                onMouseLeave={navCloseHandler}
                className={`${classes.tournament_nav} ${
                    navToggle ? classes.on : null
                }`}
            >
                <p>
                    <NavLink to="/tournament">진승 대회</NavLink>
                </p>
                <ul>
                    <li>
                        <NavLink to="/tournament/notice">대회공지</NavLink>
                    </li>
                    <li>
                        <NavLink to="/tournament/apply">대회접수</NavLink>
                    </li>
                    <li>
                        <NavLink to="/tournament/list">대회목록</NavLink>
                    </li>
                    <li>
                        <NavLink to="/tournament/result">대회결과</NavLink>
                    </li>
                </ul>
            </div>
            <nav className={classes.mainNav}>{userMenu()}</nav>
            {homeLoginMyPageBlock}
            <div className={classes.go_mall} onClick={openNewWindow}>
                진승몰
            </div>
        </>
    );

    const tournamentBlock = (
        <>
            <h1 className={classes.header_tournament_logo}>
                <NavLink to="/tournament">
                    <span>진</span>승볼링대회
                </NavLink>
            </h1>
            <div className={classes.toggle_home}>
                <ul>
                    <li
                    // className={isMain ? classes.on : ""}
                    >
                        <NavLink to="/">진승 홈</NavLink>
                    </li>
                </ul>
            </div>
            <nav className={`${classes.mainNav} ${classes.tournament_st}`}>
                {tournamentMenu()}
            </nav>
            {homeLoginMyPageBlock}
            <div
                className={`${classes.go_mall} ${classes.tournament_st}`}
                onClick={openNewWindow}
            >
                진승몰
            </div>
        </>
    );

    return (
        <>
            {isAdminUri ? (
                adminMenuBlock
            ) : (
                <header className={`${classes.header} ${classes.main_header}`}>
                    <div className={classes.header_wrap}>
                        {isTournamentUri ? tournamentBlock : mainMenuBlock}
                    </div>
                </header>
            )}
        </>
    );
}

export default MainNavigation;
